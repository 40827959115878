import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

function usePaymentRedirect(prop = {}) {
    const {resultCode, refusalReason, redirectUrls} = prop

    const history = useHistory()

    useEffect(() => {
        if(!resultCode) return
    
        const url = redirectUrls[resultCode] || redirectUrls.Error || `${redirectUrls.InternalHandling}&resultCode=${resultCode}&refusalReason=${refusalReason}`

        if(url && url.indexOf('://') !== -1) {
            window.location.href = url            

            return
        }

        history.push(url)

    }, [resultCode, refusalReason, redirectUrls, history])
}

export default usePaymentRedirect 