import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
  typography: {
    fontFamily: ['Geogtq', 'Roboto', 'Sans Serif'].join(','),
    h1: {
      fontSize: '3rem',
      fontWeight: 600,
    },
    h2: {
      fontSize: '2.25rem',
      fontWeight: 500,
    },
    h3: {
      fontSize: '2rem',
      fontWeight: 600,
    },
    h4: {
      fontSize: '1.875rem',
      fontWeight: 600,
    },
    h5: {
      fontSize: '1.5rem',
      fontWeight: 600,
    },
    h6: {
      fontSize: '1.25rem',
      fontWeight: 500,
    }
  },
  palette: {
    primary: {
      main: '#5b733c',
      light: '#7f9165'
    },
    secondary: {
      main: '#f79d00',
    },
    muted: {
      main: '#b7b7b7',
      contrastText: '#fff',
    },
    logo: {
      primary: '',
      secondary: '#FFFFFF',
      black: '#000000',
      white: '#FFFFFF',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          borderRadius: '3px',
          textTransform: 'capitalize',
          ...(ownerState.color === 'primary' && {
              backgroundColor: theme.palette.primary.light,
              '&:hover': {
                backgroundColor: theme.palette.primary.main,
              }
            }),
        }),
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.size === 'small' && {
            fontSize: '.86em',
            letterSpacing: '0.025em'
          }),
        })
      }
    }
  },
})