import React from 'react'
import PropTypes from 'prop-types'
import { Link as MuiLink } from '@mui/material'

export const Link = ({ href, target, children, ...props }) => (
  <MuiLink
    href={href}
    rel="noopener"
    target={target}
    {...props}
  >
    {children}
  </MuiLink>
)

Link.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  target: PropTypes.string
}

Link.defaultProps = {
  target: null
}