import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import { getBffUrl, getLocale } from './config'


const init = () => {
  i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: getLocale(), // TODO: Change locale to configure from domain
    interpolation: {
      escapeValue: false
    },
    debug: false,
    backend: {
      loadPath: `${getBffUrl()}translation/${getLocale()}/translation.json`, // eslint-disable-line
      customHeaders: () => ({
        'origin': `${window.location.protocol}//${window.location.host}`
      }),
    },
  })
}

export default init
