import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { Typography } from '@mui/material'
import { useHistory } from 'react-router-dom'
import queryString  from 'query-string'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { get, post } from '../../common/bff-api'
import { getAdyenClientKey, getAdyenEnvironment, getBffUrl } from '../../common/config'
import usePaymentRedirect from '../../utilHooks/payment-redirect'
import PaymentPage from './payment-page'
import { Link } from '../../blocks/buttons/link'
import { getOrderDetails } from '../../helpers/order-details-table-helper'
import { NotFoundPage } from '../not-found'

function PaymentPageContainer({ t }) {
  const history = useHistory()

  const [ orderId, setOrderId ] = useState(null)
  const [ adyenConfig, setAdyenConfig ] = useState(null)
  const [ orderDetails, setOrderDetails ] = useState(null)
  const [ responseMessage, setResponseMessage ] = useState(null)
  const [ referrer, setReferrer ] = useState(null)
  const [ redirectInfo, setRedirectInfo ] = useState()
  const [ termsAndConditionsUrl, setTermsAndConditionsUrl ] = useState(null)
  const [ totalAmount, setTotalAmount ] = useState(null)
  const [ showNotFound, setShowNotFound ] = useState(false)

  usePaymentRedirect(redirectInfo)

  const resultCodeMappings = useMemo(() => ({
    Authorised: 'success',
    Pending: 'loading',
    Received: 'loading',
    Refused: 'error',
    Error: 'error',
    Cancelled: 'error',
  }), [])

  const getHandleShopperRedirectUrl = useCallback((type) => {
    if (type === 'mobilepay') {
      return `${window.location.protocol}//${window.location.host}/payment/redirect`
    }

    return `${getBffUrl()}payment/redirect`
  }, [])

  const setupConfig = useCallback(async ({ amount, currency, locale, merchantAccount, channel }, reference, successPageUrl, isAlreadyPaid) => {
        if(isAlreadyPaid) {
            setResponseMessage({
                status: resultCodeMappings.Authorised,
                message: t('Kilroy.Payment.AdyenSkin.OrderAlreadyPaid', 'This order is already paid')
            })
        }

        const redirectUrls = {
            Authorised: successPageUrl,
            InternalHandling: window.location.href
        }

        const handleResponse = (res, component) => {
          if (res.action) {
            component.handleAction(res.action)
    
            return
          }
      
          if(res.resultCode === 'Authorised') {
            setRedirectInfo({ resultCode: res.resultCode, refusalReason: res.refusalReason, redirectUrls })

            return
          }

          setResponseMessage({
              status: resultCodeMappings[res.resultCode] || resultCodeMappings.Error,
              message:  res.refusalReason && res.refusalReason !== 'undefined' ? res.refusalReason : null
          })
        }

        const handleRequest = async (data, component, url) => {
            try {
                const res = await post(`payment/${url}`, data)
                handleResponse(res, component)
            } catch (error) {
                console.error(error)
            }
        }

        const getPaymentMethods = () => get(`payment/paymentMethods/${merchantAccount}/${channel}`)

        setAdyenConfig(
        {
            paymentMethodsResponse: await getPaymentMethods(),
            clientKey: getAdyenClientKey(),
            locale: locale.replace('_', '-'),
            environment: getAdyenEnvironment(),
            paymentMethodsConfiguration: {
              card: {
                  showPayButton: false,
                  hasHolderName: true,
                  holderNameRequired: true,
                  name: t('Kilroy.Payment.AdyenSkin.Card.Name','Credit or debit card'),
                  amount: {
                    value: amount,
                    currency
                  }
              },
              mobilepay: {
                showPayButton: false
              },
            },
            onSubmit: (state, component) => {
                const handleShopperRedirectUrl = getHandleShopperRedirectUrl(state.data.paymentMethod.type)
                if (state.isValid) {
                  handleRequest({... state.data, orderId, reference, handleShopperRedirectUrl, redirectUrls, currency, amount, merchantAccount, channel }, component, 'initiatePayment')
                }
              },
              onAdditionalDetails: (state, component) => {
                handleRequest(state.data, component, 'submitAdditionalDetails')
            },
        })
  }, [orderId, setAdyenConfig, setResponseMessage, getHandleShopperRedirectUrl, resultCodeMappings, t])

  const showValidationOnLoad = useCallback(() => {
    const parsedQueryString = queryString.parse(history.location.search)

    if(!parsedQueryString.resultCode) return

    setResponseMessage({
        status: resultCodeMappings[parsedQueryString.resultCode] || resultCodeMappings.Error,
        message:  parsedQueryString.refusalReason && parsedQueryString.refusalReason !== 'undefined' ? parsedQueryString.refusalReason : null
    })
  }, [history, resultCodeMappings, setResponseMessage])

  useEffect(() => {
    if(!orderId) return

    (async () => {
      try {
        const order = await get(`payment/order/${orderId}`, { cache: 'no-cache' })

        setOrderDetails(getOrderDetails(order, t))
        setupConfig(order.payment, order.reference, order.successPageUrl, order.isAlreadyPaid)
        setReferrer(order.referrer)
        setTotalAmount(`${(order.payment.amount / 100).toFixed(2)} ${order.payment.currency}`)
        if (order.termsAndConditionsUrl) {
          const termsAndConditionsLink = 
            <Typography variant="body1">
              {t('Kilroy.NavigatorPayment.TermsAndConditionsCheckBoxText','Please accept our')} <Link
                href={order.termsAndConditionsUrl}
                target="blank"
              >{t('Kilroy.NavigatorPayment.TermsAndConditionsLinkText','terms and conditions')}</Link>
            </Typography>
          setTermsAndConditionsUrl(termsAndConditionsLink)
        }

        showValidationOnLoad() 
      } catch (error) {
        console.info(error)
        setShowNotFound(true)
      }
    })()

  }, [
    orderId,
    setupConfig,
    setOrderDetails,
    setReferrer,
    showValidationOnLoad,
    setTermsAndConditionsUrl,
    setShowNotFound,
    t
  ])

  useEffect(() => {
    const parsedQueryString = queryString.parse(history.location.search)

    setOrderId(parsedQueryString.orderId)
  }, [setOrderId, history])

  const handleOnBackClick = () => {
    responseMessage ? setResponseMessage(null) : window.location.replace(referrer)
  }

  if (showNotFound) {
    return (<NotFoundPage/>)
  }

  return (
    <PaymentPage
      adyenConfig={adyenConfig}
      handleOnBackClick={referrer && referrer !== 'undefined' ? handleOnBackClick : null}
      responseMessage={responseMessage}
      orderDetails={orderDetails}
      termsAndConditionsLabel={termsAndConditionsUrl}
      totalAmount={totalAmount}
    />
  )
}

PaymentPageContainer.propTypes = {
  t: PropTypes.func.isRequired
}


export default withTranslation()(PaymentPageContainer)
  
