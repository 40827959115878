import React, { useEffect, useRef, useState, useCallback } from 'react'
import AdyenCheckout from '@adyen/adyen-web'
import PropTypes from 'prop-types'
import '@adyen/adyen-web/dist/adyen.css'
import { Card, CardActions, CardContent, CircularProgress, Grid } from '@mui/material'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { withTranslation } from 'react-i18next'
import { PrimaryButton } from '../../blocks/buttons/primary-button'
import { LabelCheckbox } from '../../blocks/input/label-checkbox'
import { ContainerLogo } from '../../composite/container/container-logo'

function PaymentPage({ orderDetails, adyenConfig, handleOnBackClick, responseMessage, t, termsAndConditionsLabel, totalAmount }) {
  const dropinContainerRef = useRef(null)
  const [dropin, setDropin] = useState(null)
  const [termsAndConditionsChecked, setTermsAndConditionsChecked] = useState(false)
  const [showRequiredWarning, setShowRequiredWarning] = useState(false)
  const [responseMessageVisible, setResponseMessageVisible] = useState(false)
  const theme = useTheme()
  const largeScreen = useMediaQuery(theme.breakpoints.up('md'))

  const handleOnSubmit = useCallback(() => {
    if (dropin && (!termsAndConditionsLabel || termsAndConditionsChecked)) {
      dropin.submit()
    }

    setShowRequiredWarning(!termsAndConditionsChecked)
  }, [termsAndConditionsChecked, termsAndConditionsLabel, dropin])

  const handleOnRetry = useCallback(() => {
    if (dropin) {
      dropin.setStatus()
      setResponseMessageVisible(false)
    }

  }, [dropin, setResponseMessageVisible])

  useEffect(() => {
    if(adyenConfig === null) return

    const checkout = new AdyenCheckout(adyenConfig)
    const dropinCheckout = checkout.create('dropin').mount(dropinContainerRef.current)
    setDropin(dropinCheckout)

  }, [adyenConfig, setDropin])

  useEffect(() => {
    if (dropin) {
      dropin.setStatus(responseMessage?.status, { message:  responseMessage?.message })
    }
    
    if (responseMessage) {
      setResponseMessageVisible(true)
    }
  }, [responseMessage, dropin, setResponseMessageVisible])

  return (
    <ContainerLogo logoMargin="0 0 15px">
        {handleOnBackClick && <PrimaryButton color="muted" onClick={handleOnBackClick}>{t('Kilroy.Payment.AdyenSkin.Button.Back', 'Back')}</PrimaryButton>}
        <Grid container spacing={3} direction={ largeScreen ? 'row' : 'column-reverse' } sx={{ pt: 1 }}>
          <Grid item xs={12} md={6}>
            <Card sx={{p: 3}}>
              <CardContent>
                <div id="dropin-container" ref={dropinContainerRef} />
                {!dropin && <Grid container justifyContent="center" alignItems="center"><Grid item><CircularProgress /></Grid></Grid>}
              </CardContent>
              <CardActions>
                  {totalAmount && !responseMessageVisible && <PrimaryButton disabled={termsAndConditionsLabel && !termsAndConditionsChecked} onClick={handleOnSubmit} fullWidth>{`${t('Kilroy.Payment.AdyenSkin.Button.Pay', 'Pay')} ${totalAmount}`}</PrimaryButton>}
                  {responseMessageVisible && responseMessage?.status !== 'success' && <PrimaryButton color="muted" onClick={handleOnRetry} fullWidth>{t('Kilroy.Payment.AdyenSkin.Button.Retry', 'Retry')}</PrimaryButton>}
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card sx={{p: 3}}>
                {orderDetails && 
                  <CardContent>
                    <Typography variant="h5" sx={{ textTransform: 'uppercase', pb: 1 }}>
                      {orderDetails.heading}
                    </Typography>
                    {orderDetails.description && <Typography variant="subtitle1">{orderDetails.description}</Typography>}
                    {orderDetails.tables}
                    {!responseMessageVisible && termsAndConditionsLabel && 
                      <LabelCheckbox
                        error={showRequiredWarning && !termsAndConditionsChecked}
                        label={termsAndConditionsLabel}
                        checked={termsAndConditionsChecked}
                        onChange={(e) => {setTermsAndConditionsChecked(e.target.checked)}}
                      />
                    }
                  </CardContent>
                }
                {!orderDetails && <Grid container justifyContent="center" alignItems="center"><Grid item><CircularProgress /></Grid></Grid>}
            </Card>
          </Grid>
        </Grid>
    </ContainerLogo>
  )
}

const cardConfiguration = PropTypes.exact({
  card: PropTypes.exact({
    showPayButton: PropTypes.bool,
    hasHolderName: PropTypes.bool,
    holderNameRequired: PropTypes.bool,
    name: PropTypes.string,
    amount: PropTypes.exact({
      value: PropTypes.number,
      currency: PropTypes.string
    })
  }),
  mobilepay: PropTypes.exact({
    showPayButton: PropTypes.bool,
  })
})

PaymentPage.defaultProps = {
  adyenConfig: null,
  responseMessage: null,
  orderDetails: null,
  termsAndConditionsLabel: null,
  totalAmount: null,
  handleOnBackClick: null
}

PaymentPage.propTypes = {
  adyenConfig: PropTypes.shape(
    {
      paymentMethodsResponse: PropTypes.object, // eslint-disable-line
      clientKey: PropTypes.string,
      locale: PropTypes.string,
      environment: PropTypes.string,
      paymentMethodsConfiguration:cardConfiguration,
      onSubmit: PropTypes.func,
      onAdditionalDetails: PropTypes.func,
    }
  ),
  handleOnBackClick: PropTypes.func,
  responseMessage: PropTypes.shape(
    {
      status: PropTypes.string,
      message: PropTypes.string
    }
  ),
  orderDetails: PropTypes.shape(
    {
      heading: PropTypes.string,
      description: PropTypes.string,
      tables: PropTypes.node,
    }
  ),
  t: PropTypes.func.isRequired,
  termsAndConditionsLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  totalAmount: PropTypes.string
}

export default withTranslation()(PaymentPage)
  