import React, { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import { get } from '../../common/bff-api'
import usePaymentRedirect from '../../utilHooks/payment-redirect'
import { ContainerLogo } from '../../composite/container/container-logo'

function PaymentRedirectPage() {
  const [redirectInfo, setRedirectInfo] = useState()

  usePaymentRedirect(redirectInfo)

  useEffect(() => {
    (async () => {
      const rs = await get(`payment/handleShopperRedirect${window.location.search}`)
      const newRedirectUrls = {...rs.redirectUrls}
      
      if(!newRedirectUrls[rs.resultCode] && newRedirectUrls.InternalHandling) {
        newRedirectUrls[rs.resultCode] = `${newRedirectUrls.InternalHandling}&resultCode=${rs.resultCode}&refusalReason=${rs.refusalReason || rs.resultCode }`
      }

      setRedirectInfo({ resultCode: rs.resultCode, refusalReason: rs.refusalReason, redirectUrls: newRedirectUrls })
    })()
  }, [])

  return (
    <ContainerLogo>
        <Typography variant="h6">Redirecting...</Typography>
    </ContainerLogo>
  )
}

export default PaymentRedirectPage
