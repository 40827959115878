import React from 'react'
import Button from '@mui/material/Button'
import PropTypes from 'prop-types'

export const PrimaryButton = ({ variant, disableElevation, color, size, disabled, href, children, ...props }) => (
  <Button
    variant={variant}
    disableElevation={disableElevation}
    color={color}
    size={size}
    disabled={disabled}
    href={href}
    {...props}
    >
      {children}
  </Button>
)

PrimaryButton.propTypes = {
  variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
  disableElevation: PropTypes.bool,
  color: PropTypes.oneOf(['primary', 'secondary', 'muted']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  disabled: PropTypes.bool,
  href: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string
  ]).isRequired,
}

PrimaryButton.defaultProps = {
  variant: 'contained',
  disableElevation: true,
  color: 'primary',
  size: 'large',
  disabled: false,
  href: null
}
