import React from 'react'
import { getBrand } from '../common/config'
import {ReactComponent as JyskRejsebureauLogo} from '../assets/jysk-rejsebureau/logo.svg'
import {ReactComponent as KilroyLogo} from '../assets/kilroy/logo.svg'

const BrandLogos = {
    kilroy: KilroyLogo,
    jr: JyskRejsebureauLogo,
}

export const BrandLogo = ({ ...props }) => {
    const Logo = BrandLogos[getBrand()]

    return <Logo {...props} />
}