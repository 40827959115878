import React from 'react'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { ContainerLogo } from '../../composite/container/container-logo'

function NotFoundPage() {
  const { t } = useTranslation(['translation'])

    return (
      <ContainerLogo>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
        >
          <Grid item sx={{pt: 3}}>
            <Card sx={{p: 3}}>
              <CardContent>
                <Typography align="center" variant="subtitle1">{t('Kilroy.Payment.AdyenSkin.Error.NotFound', 'Not found')}</Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </ContainerLogo>
    )
  }
  
  export default NotFoundPage
  