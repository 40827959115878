import { languageCodeDomains } from '../helpers/language-codes-mapper'

const fetch = require('node-fetch')

const rxGetCountryCode = /^[a-z]{2}-[A-Z]{2}$/

const getConfig = key => {
    if(!window.CONFIG) throw new Error('window.CONFIG was not found')

    if(!window.CONFIG[key]) throw new Error(`Config key "${key}" was not found in window.CONFIG`)

    if(window.CONFIG[key].indexOf('%') === 0) throw new Error(`Config key "${key}" has the value "${window.CONFIG[key]}" which indicates that it isn't set`)

    return window.CONFIG[key]
}

export const getBffUrl = () => getConfig('BFF_URL')

export const getBffApiUrl = () => `${getBffUrl()}api/`

export const getAdyenClientKey = () => getConfig('PAYMENT_CLIENT_KEY')

export const getBrand = () => getConfig('BRAND')

export const getBrandDisplayName = () => getConfig('BRAND_DISPLAY_NAME')

export const getAdyenEnvironment = () => getConfig('PAYMENT_ENVIRONMENT')

export const getLocale = () => {
    const pathNameLngCode = window.location.pathname.split('/').filter(name => rxGetCountryCode.exec(name))
    if (pathNameLngCode.length > 0) {
        return pathNameLngCode[0]
    }

    const hostArray = window.location.host.split('.')
    const langCode = languageCodeDomains[hostArray[hostArray.length - 1]]

    return langCode && langCode !== 'undefined' ? langCode : 'en-GB'
}


export const configLoader = async function configLoader(url) {
    const response = await fetch(url)
    window.CONFIG = await response.json()
}
