import Typography from '@mui/material/Typography'
import React from 'react'
import { CustomTable } from '../composite/tables/custom-table'

export const getOrderDetails = ({payment, booking, customer}, t) => {
  const orderLines = payment.orderLines ? payment.orderLines.map(orderLine => [orderLine.description, `${orderLine.amount} ${payment.currency}`]) : []
  const bookingInformation = payment.bookingInformation ? payment.bookingInformation.map(item => [item.label, item.text]) : []

  return ({
    heading: t('Kilroy.Payment.AdyenSkin.Headline', 'Payment'),
    tables: 
      <>
        <Typography variant="h6" sx={{ pb: 1 }}>{t('Kilroy.NavigatorPayment.OrderDetails.Header', 'Booking information')}</Typography>
        <CustomTable
          tableRows={[
            [t('Kilroy.NavigatorPayment.CustomerDetails.FirstName', 'First Name'), customer.firstName],
            [t('Kilroy.NavigatorPayment.CustomerDetails.LastName', 'Last Name'), customer.lastName],
            [t('Kilroy.NavigatorPayment.CustomerDetails.Email', 'Email'), customer.email],
            [t('Kilroy.NavigatorPayment.OrderDetails.Reference', 'Booking Id'), booking.id],
            ...bookingInformation
          ]}
        />
        <Typography variant="h6" sx={{ pb: 1, pt: 1 }}>{t('Kilroy.NavigatorPayment.PaymentSummaryTitle', 'Payment summary')}</Typography>
        <CustomTable
          highligtRowIndex={orderLines.length}
          tableRows={[
            ...orderLines,
            [t('Kilroy.NavigatorPayment.PaymentTotal', 'Total'), `${(payment.amount / 100).toFixed(2)} ${payment.currency}`]
          ]}
        />
      </>
  })
}