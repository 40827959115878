const apiResponseError = response => {
    const error = new Error(`Request to ${response.url} failed!`)
    error.status = response.status
    error.statusText = response.statusText
    
    return error
}

const handleRequest = async (url, options = {}) => {
    const response = await fetch(url, options)

    if(response.ok)
        return response.json()

    throw apiResponseError(response)
}

const handleRequestWithBody = (url, method, body, options = {}) => handleRequest(
    url,
    {...options,
        method,
        headers: {
            ...options.headers,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body) 
    })

export const get = (url, options) => handleRequest(url, options)

export const post = (url, body, options) => handleRequestWithBody(url, 'POST', body, options)

export const put = (url, body, options) => handleRequestWithBody(url, 'PUT', body, options)
