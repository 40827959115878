import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'
import { PaymentPage } from './pages/payment'
import { PaymentRedirectPage } from './pages/payment-redirect'
import { NotFoundPage } from './pages/not-found'

function App() {
  return <Router>
      <>
      <Switch>
          <Route path="/:lang/payment/redirect">
            <PaymentRedirectPage />
          </Route>
          <Route path="/payment/redirect">
            <PaymentRedirectPage />
          </Route>
          <Route path="/:lang/payment">
            <PaymentPage />
          </Route>
          <Route path="/payment">
            <PaymentPage />
          </Route>
          <Route path="*">
            <NotFoundPage />
          </Route>
        </Switch>
    </>
  </Router>
}

export default App
