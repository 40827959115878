import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import {BrandLogo} from '../../helpers/logo-helper'

const widths = {
  small: '85px',
  medium: '125px',
  large: '180px',
}

export const Logo = ({size, color, padding, bgColor, margin}) => {
  const theme = useTheme()

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      color={color}
    >
      <Grid
        item
        sx={{
          boxSizing: 'content-box',
          bgcolor: theme.palette.logo[bgColor],
          p: padding,
          width: widths[size],
          m: margin
        }}
      >
        <BrandLogo fill={theme.palette.logo[color]} />
      </Grid>
    </Grid>
  )
}

Logo.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  color: PropTypes.oneOf(['primary', 'secondary', 'white', 'black']),
  padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  margin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  bgColor: PropTypes.oneOf(['primary', 'secondary', 'white', 'black'])
}

Logo.defaultProps = {
  size: 'medium',
  color: 'primary',
  padding: 0,
  margin: 0,
  bgColor: null
}
