import React from 'react'
import PropTypes from 'prop-types'
import { FormControlLabel, FormGroup, Checkbox } from '@mui/material'
import { red } from '@mui/material/colors'

const errorStyle = { border: `2px solid ${red[900]}`, bgcolor: red[100], borderRadius: '6px', pl: 2}

export const LabelCheckbox = ({ label, checked, size, error, ...props }) => (
  <FormGroup sx={error ? errorStyle : {}}>
    <FormControlLabel control={<Checkbox checked={checked} size={size} />} label={label} {...props}/>
  </FormGroup>
)

LabelCheckbox.defaultProps = {
  checked: false,
  size: 'small',
  error: false
}

LabelCheckbox.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  label: PropTypes.oneOfType(
    [
      PropTypes.number,
      PropTypes.string,
      PropTypes.node
    ]
  ).isRequired,
  checked: PropTypes.bool,
  error: PropTypes.bool 
}