import { getBffApiUrl } from './config'
import { get as apiGet, post as apiPost, put as apiPut } from './api-call'

const createBffUrl = url => `${getBffApiUrl()}${url}`

const prepareOptionsForBff = options => ({
        ...options,
        headers: {
            ...(options ? options.headers : {}),
            'origin': `${window.location.protocol}//${window.location.host}`
        }
    })

export const get = async (url, options) => apiGet(createBffUrl(url), prepareOptionsForBff(options))

export const post = async (url, body, options) => apiPost(createBffUrl(url), body, prepareOptionsForBff(options))

export const put = async (url, body, options) => apiPut(createBffUrl(url), body, prepareOptionsForBff(options))